.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

  p {
    margin: 0;
  }
}

.listing__top-container {
  background-color: #fff;
  z-index: 2;
  top: 68px;
  position: sticky;
  padding: 12px;
}

.body-wrapper {
  transition: 0.5s;
}

.show-sidebar {

  @include media-breakpoint-up(lg) {

    .body-wrapper {
      margin-left: auto;
      transition: 0.5s;
      width: calc(100% - 250px);
    }

    .app-header {
      left: 250px;
      width: auto;
      transition: 0.5s;
    }
  }
}