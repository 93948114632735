// ----------------------------------------------
// Layout Style
// ----------------------------------------------

.page-wrapper {
  position: relative;

}

.body-wrapper {
  position: relative;

  >.container-fluid {
    max-width: $boxed-width;
    margin: 0 auto;
    padding: $grid-gutter-width;
    transition: 0.2s ease-in;
  }
}

@include media-breakpoint-down(md) {
  .body-wrapper {
    >.container-fluid {
      padding: 30px 20px;
    }
  }
}

.simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.5) !important;
}

#main-wrapper[data-layout="vertical"] {

  // Sidebar position
  &[data-sidebar-position="fixed"] {
    .left-sidebar {
      position: fixed;
      top: 0;
    }
  }

  // Header position
  &[data-header-position="fixed"] {
    .app-header {
      position: fixed;
      z-index: 10;
    }

    .body-wrapper {
      >.container-fluid {
        padding-top: calc(#{$header-height} + 15px);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #main-wrapper[data-layout="vertical"] {

    // Fixed Header + Minisidebar
    &[data-header-position="fixed"][data-sidebartype="mini-sidebar"] {
      .app-header {
        width: calc(100%);
      }
    }

    // Header position
    &[data-header-position="fixed"] {
      .app-header {
        width: calc(100% - #{$sidebar-width-full});
      }
    }

    // Full Sidebar
    &[data-sidebartype="full"] {
      .body-wrapper {
        margin-left: $sidebar-width-full;
      }
    }

  }
}

//
// Responsive view (below desktop view)
//
@media (max-width: 1199px) {
  #main-wrapper[data-layout="vertical"] {

    &[data-sidebartype="mini-sidebar"],
    &[data-sidebartype="full"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }

      &.show-sidebar {
        .left-sidebar {
          left: 0;
        }
      }
    }

  }
}