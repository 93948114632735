// ----------------------------------------------
// Background Style
// ----------------------------------------------

.radial-gradient {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.3;
        background: #fafafa;
        animation: 15s ease 0s infinite normal none running gradient;
    }
}
