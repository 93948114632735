// ----------------------------------------------
// Icon Size Style
// ----------------------------------------------

.round-8 {
    width: 8px;
    height: 8px;
}
.round-20 {
    width: 20px !important;
    height: 20px !important;
}